/* body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
} */

.content {
  padding: 2rem;
}

.container {
  max-width: 1200px; 
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
}

.landing-content > h1 {
  color: #191C3D;
  font-size: 2.6em;
  font-weight: bolder;
  line-height: 1.1;
  margin-bottom: 1rem; 
}

.landing-content > p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.95em;
  font-weight:400;
}

.cta-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #003e46;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: 100%; /* Default to full width for mobile */
  margin: 0 auto; /* Center the button */
}

.cta-button.variation {
  background-color: #3498db;
}

.cta-button.variation-2 {
  background-color: white;
  border: 1px solid #3498db;
  color: #3498db;
}

.cta-button.variation-3 {
  background-color: white;
  border: 1px solid #003e46;
  color: #003e46;
}

.cta-button:hover {
  background-color: #002a30;
}

.cta-button.variation:hover {
  background-color: #1c5378;
}

.cta-button.variation-2:hover {
  background-color: white;
  border: 1px solid #1c5378;
  color: #1c5378;
}

.cta-button.variation-3:hover {
  background-color: white;
  border: 1px solid #002a30;
  color: #002a30;
}

.page {
  display: block;
}

.image-container {
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  overflow: hidden;
}

#uploadedImagePreview {
  width: 100%;
  height: auto;
  display: block;
  filter: grayscale(100%)  blur(1px) ;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
}

.scan-line {
  position: absolute;
  top: 0; /* Changed back to top */
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #3498db;
}
/* Remove the @keyframes rule for scan */
#resultPage img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

@media (max-width: 767px) {
  .landing-container {
      padding-top: 0;
  }

  .cta-button {
      display: block;
      width: 100%;
      box-sizing: border-box;
      max-width: 100%;
  }
}

.landing-content {
  max-width: 100%;
}

.landing-video {
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .landing-container {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      justify-content: space-between;
  }

  .landing-content {
      max-width: 55%;
  }

  .landing-video {
      max-width: 45%;
      max-height: 80vh; /* Limit height to 80% of viewport height */
      width: auto; /* Allow width to adjust proportionally */
      object-fit: contain; /* Maintain aspect ratio */
      margin-top: 0;
      margin-left: auto; /* Right align the video */
  }         
}

.progress-bar {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

/* Add these new styles for the dropdown */
#stateDropdown {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  cursor: pointer;
}

#stateDropdown:focus {
  outline: none;
  border-color: #003e46;
  box-shadow: 0 0 0 2px rgba(0, 62, 70, 0.2);
}

#submitStateButton {
  margin-top: 15px;
  width: 100%;
  max-width: 300px;
}

@media (max-width: 767px) {
  #stateDropdown,
  #submitStateButton {
      max-width: none;
  }
}

/* Add these new styles for the feedback buttons */
.feedback-button {
  font-size: 24px;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.feedback-button:hover {
  background-color: #e0e0e0;
}

/* Add this to your existing CSS styles */
.paywall-container {
  font-family: 'Inter', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
.paywall-header {
  text-align: center;
  margin-bottom: 20px;
}
.paywall-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}
.paywall-subtitle {
  font-size: 18px;
  color: #666;
}
.app-rating {
  display: block;
  margin: 0 auto 20px;
  height: 80px;
}
.payment-tiers {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}
.tier {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.tier.selected {
  border-color: #003e46;
  background-color: #f0f8ff;
}
.tier-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.tier-price {
  font-size: 18px;
  color: #003e46;
  margin-bottom: 10px;
}
.tier-body {
  font-size: 16px;
  color: #333;
}
.bullet-point {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.bullet-point::before {
  content: '•';
  margin-right: 10px;
  color: #003e46;
}

.blurred-section {
  filter: blur(5px);
  user-select: none;
  pointer-events: none;
}

.blur-overlay {
  position: relative;
}

/* .blur-overlay::after {
  content: "Unlock Full Checklist";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
} */

.code-checker-result {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.code-section {
  margin-bottom: 30px;
  background-color: white;
  border-radius: 4px;
  padding: 15px;
}
.code-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}
.importance-tag {
  font-size: 0.8em;
  font-weight: normal;
  color: #666;
  margin-left: 10px;
  padding: 2px 6px;
  background-color: #f0f0f0;
  border-radius: 4px;
}
.checklist {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}
.checklist li {
  margin-bottom: 10px;
  padding-left: 40px;
  position: relative;
}
.checklist li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 3px;
}