tr {
    border-bottom: 1px solid #ddd;
  }

tr:nth-child(even) {
background-color: rgba(0, 177, 201, 0.2);
}

/* th:nth-child(even),td:nth-child(even) {
background-color: rgba(0, 177, 201, 0.4);
} */