body {
    background-color: #F0F0F0;
    font-size: 20px;
    line-height: 32px;
}

h1, h2, h3, h4, h5, h6 {
    color: #181C3F;
    font-weight: bold;
}

h1, h2 {
    line-height: 44px;
}

a {
    color: #048192;
}