.section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.section > div {
    width: 45%;
}

@media screen and (max-width: 768px) {
    .section {
        flex-direction: column;
    }

    .section > div {
        width: 100%;
    }

    .mobile-reverse {
        flex-direction: column-reverse;
    }
}
