@import url('react-responsive-carousel/lib/styles/carousel.min.css');

.scroll-down {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 400px;
    height: 55px;
}

.mouse {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 3px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
}