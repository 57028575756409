.fading-box {
    mask-image: -webkit-gradient(
        linear,
        right top,
        left top,
        from(rgba(0, 0, 0, 1)),
        color-stop(50%, rgba(0, 0, 0, 1)),
        color-stop(80%, rgba(0, 0, 0, .2)),
        to(rgba(0, 0, 0, 0))
    );
    -webkit-mask-image: -webkit-gradient(
        linear,
        right top,
        left top,
        from(rgba(0, 0, 0, 1)),
        color-stop(50%, rgba(0, 0, 0, 1)),
        color-stop(80%, rgba(0, 0, 0, .2)),
        to(rgba(0, 0, 0, 0))
    );
}
